import Rellax from 'rellax';

$(document).ready( function(){
    var headerParallax = $('body').data('header-parallax');

    if(headerParallax == 'enabled'){
        $('<div class="rellax-bg"></div>').insertAfter('#content-section > .header-img-size-box');
        var rellaxHeader = new Rellax('#content-section > .header-img-size-box', {
            speed: -7
        });

        var rellaxHeaderContent = new Rellax('#content-section > .header-img-size-box .header-title, #content-section > .header-img-size-box a.btn', {
            speed: 1
        });
    }

    var headerTransition = $('body').data('header-transition');
    if(headerTransition == 'fade'){
        $('#content-section > .header-img-size-box .carousel').addClass('carousel-fade');
    }
});
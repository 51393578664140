/* Achtung: Dieses Skript wird auch im Backend eingebunden! */
/* Embedding Instagram posts START */
/*
jQuery(document).ready(function ($) {
    var instaElemCounter = 0;
    $('.medinstagram-post:not(.processed)').each(function () {
        var instaElem = $(this);
        instaElem.addClass('processed');
        instaElemCounter = instaElemCounter + 1;
        if (typeof instgrm !== 'undefined' && instaElemCounter == $('.medinstagram-post').length) {
            instgrm.Embeds.process();
        }
    });
});
*/
/* Embedding Instagram posts END */
import Cookies from './plugins/js.cookie';

var url = window.location.href;
var urlArr = url.split("/");
var curDomain =  urlArr[2];
var curProtocol = urlArr[0] + '//';

//Check Touch Devices
function isTouchDevice(){
    //Check Device
    return ('ontouchstart' in document.documentElement);
};

/* Social Media Optin */
export function activateOptinContent(elem){
    if(!(typeof elem === "undefined")){
        if(elem.hasClass('optin-elem-yt')){
            var optinElems = $('.optin-elem.deactivated.optin-elem-yt');
            var cookieName = 'optin_yt';
        }
        else if(elem.hasClass('optin-elem-vimeo')){
            var optinElems = $('.optin-elem.deactivated.optin-elem-vimeo');
            var cookieName = 'optin_vimeo';
        }
        else if(elem.hasClass('optin-elem-fb')){
            var optinElems = $('.optin-elem.deactivated.optin-elem-fb');
            var cookieName = 'optin_fb';

            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_EN/sdk.js#xfbml=1&version=v2.11';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));

            window.fbAsyncInit = function() {
                FB.XFBML.parse();
            };
        }
        else if(elem.hasClass('optin-elem-twitter')){
            var optinElems = $('.optin-elem.deactivated.optin-elem-twitter');
            var cookieName = 'optin_twitter';

            (function (global, doc, optinElems) {
                global.twttr = global.twttr || (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0],
                        t = global.twttr || {};
                    if (d.getElementById(id)) return t;
                    js = d.createElement(s);
                    js.id = id;
                    js.src = 'https://platform.twitter.com/widgets.js';
                    fjs.parentNode.insertBefore(js, fjs);

                    t._e = [];
                    t.ready = function (f) {
                        t._e.push(f);
                    };

                    return t;
                }(doc, 'script', 'twitter-wjs'));

                global.twttr.ready(function () {
                    optinElems.each(function() {
                        var tweetContainer = $(this).find('.tweet-container').get(0);
                        var tweetUrlParts = tweetContainer.dataset.url.split('/');
                        var tweetId = tweetUrlParts[tweetUrlParts.length - 1];
                        global.twttr.widgets.createTweet(
                            tweetId,
                            tweetContainer,
                            {
                                'cards': $(this).get(0).dataset.paramsCards,
                                'conversation': $(this).get(0).dataset.paramsConversation,
                                'theme': $(this).get(0).dataset.paramsTheme,
                                'linkColor': $(this).get(0).dataset.paramsLinkColor,
                                'width': $(this).get(0).dataset.width,
                                'align': $(this).get(0).dataset.align,
                                'lang': $(this).get(0).dataset.lang,
                                'dnt': $(this).get(0).dataset.paramsDnt,
                            }
                        ).then(function (el) {
                            window.jQuery(el.shadowRoot).find(".EmbeddedTweet").css("max-width", "none").css('border-radius', window.jQuery(el).closest('.elem-rounded').css('border-radius'));
                            window.jQuery(el.shadowRoot).find(".CallToAction").css('border-bottom-left-radius', window.jQuery(el).closest('.elem-rounded').css('border-radius')).css('border-bottom-right-radius', window.jQuery(el).closest('.elem-rounded').css('border-radius'));
                        });
                    });
                });
            })(window, window.document, optinElems);
        }
        else if(elem.hasClass('optin-elem-instagram')){
            var optinElems = $('.optin-elem.deactivated.optin-elem-instagram');
            var cookieName = 'optin_instagram';

            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = '//www.instagram.com/embed.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'instagram-embed'));

            var instaElemCounter = 0;
            optinElems.each(function(){
                var instaElem = $(this).find('.medinstagram-post');
                var posturl = instaElem.data('postUrl');
                var dataObj = {
                    url: posturl,
                    omitscript: true
                }
                $.ajax({
                    dataType: "json",
                    url: 'https://api.instagram.com/oembed',
                    data: dataObj,
                    success: function(data){
                        instaElem.html(data.html);
                        instaElemCounter = instaElemCounter + 1;
                        if ( typeof instgrm !== 'undefined' && instaElemCounter == optinElems.length ) {
                            instgrm.Embeds.process();
                        }
                    }
                });
            });
        }
        else if(elem.hasClass('optin-elem-map-google')){
            var optinElems = $('.optin-elem.deactivated.optin-elem-map-google');
            var cookieName = 'optin_map_google';
            var apiKey = optinElems.first().find('.maplocation-map').data('apikey');

            if (typeof(window.ezgmaplocationMapsScriptLoaded) == 'undefined') {
                (function (win, doc) {
                    var myScript = document.createElement('script');
                    if(apiKey){
                        myScript.src = '//maps.googleapis.com/maps/api/js?key=' + apiKey;
                    }
                    else {
                        myScript.src = '//maps.googleapis.com/maps/api/js';
                    }

                    win.ezgmaplocationMapsScriptLoaded = true;
                    doc.body.appendChild(myScript);
                })(window, document)
            }

            setTimeout(function(){
                (function (win) {
                    win.mapView();
                })(window);
            }, 300);
        }
        else if(elem.hasClass('optin-elem-map-osm')){
            var optinElems = $('.optin-elem.deactivated.optin-elem-map-osm');
            var cookieName = 'optin_map_osm';

            if (typeof(window.ezgmaplocationMapsScriptLoaded) == 'undefined') {
                (function (win, doc) {
                    var myScript = document.createElement('script');
                    var myCss = document.createElement('link');
                    myScript.src = '/assets/mediatakmubundle/static/build/mediata-kmu-bundle-leaflet-js.js';
                    myCss.rel = "stylesheet";
                    myCss.href = "/assets/mediatakmubundle/static/build/mediata-kmu-bundle-leaflet-css.css";
                    win.ezgmaplocationMapsScriptLoaded = true;
                    doc.head.appendChild(myCss);
                    doc.body.appendChild(myScript);
                })(window, document)
            }

            setTimeout(function(){
                (function (win) {
                    win.mapView();
                })(window);
            }, 300);
        }

        optinElems.each(function(){
            $(this).css('width', 'auto').css('padding-top', '0');
            $(this).find('.infobox.optin').fadeOut(300, function(){
                $(this).remove();
            });
            $(this).removeClass('deactivated');
            // Youtube, Vimeo
            if($(this).find('iframe').length > 0){
                var iframe = $(this).find('iframe');
                iframe.attr('src', iframe.data('src')).hide().removeClass('hidden').fadeIn(300);
            }
        });

        var secure = true;
        if(location.protocol == 'http:'){
            secure = false;
        }

        Cookies.set(cookieName, 'accepted', {
            expires: 3650,
            domain: curDomain,
            path: '/',
            secure: secure
        });
    }
    return false;
}

jQuery(document).ready(function ($) {
    if(Cookies.get('optin_yt') == 'accepted'){
        $('.optin-elem.deactivated.optin-elem-yt').each(function(){
            activateOptinContent($(this));
        });
    }
    if(Cookies.get('optin_vimeo') == 'accepted'){
        $('.optin-elem.deactivated.optin-elem-vimeo').each(function(){
            activateOptinContent($(this));
        });
    }
    if(Cookies.get('optin_fb') == 'accepted'){
        $('.optin-elem.deactivated.optin-elem-fb').each(function(){
            activateOptinContent($(this));
        });
    }
    if(Cookies.get('optin_twitter') == 'accepted'){
        $('.optin-elem.deactivated.optin-elem-twitter').each(function(){
            activateOptinContent($(this));
        });
    }
    if(Cookies.get('optin_instagram') == 'accepted'){
        $('.optin-elem.deactivated.optin-elem-instagram').each(function(){
            activateOptinContent($(this));
        });
    }
    if(Cookies.get('optin_map_google') == 'accepted'){
        $('.optin-elem.deactivated.optin-elem-map-google').each(function(){
            activateOptinContent($(this));
        });
    }
    if(Cookies.get('optin_map_osm') == 'accepted'){
        $('.optin-elem.deactivated.optin-elem-map-osm').each(function(){
            activateOptinContent($(this));
        });
    }

    $('.optin-elem-fb').each(function(){
        var curWidth = $(this).outerWidth();
        var curHeight = $(this).outerHeight();
        if(curWidth > 750){
            var newHeight = Math.ceil((750 / curWidth) * curHeight);
            $(this).width(750).css('padding-top', newHeight+'px');
        }
    });

    $('.optin-elem-tw').each(function(){
        var curWidth = $(this).outerWidth();
        var curHeight = $(this).outerHeight();
        if(curWidth > 870){
            var newHeight = Math.ceil((870 / curWidth) * curHeight);
            $(this).width(870).css('padding-top', newHeight+'px');
        }
    });

    $('.optin-elem-fb .infobox, .optin-elem-twitter .infobox, .optin-elem-instagram .infobox').each(function(){
        var parentHeight = $(this).closest('.optin-elem').outerHeight() + 1;
        $(this).css('min-height', parentHeight+'px');
    });

    $('.infobox.optin').hide().removeClass('hidden');

    if (isTouchDevice()) {
        $('.optin-elem.deactivated').each(function(){
            var optinElemHammer = new Hammer($(this)[0]);
            var optinElem = $(this);
            optinElemHammer.on("tap", function(){
                if (optinElem.find('.infobox.optin').hasClass('active')) {
                    optinElem.find('.infobox.optin').fadeOut(300).removeClass('active');
                    optinElem.removeClass('allow-overflow');
                    optinElem.closest('.embedded-content').removeClass('allow-overflow');
                    optinElem.closest('.embed-responsive').removeClass('allow-overflow');
                } else {
                    optinElem.find('.infobox.optin').fadeIn(300).addClass('active');
                    optinElem.addClass('allow-overflow');
                    optinElem.closest('.embedded-content').addClass('allow-overflow');
                    optinElem.closest('.embed-responsive').addClass('allow-overflow');
                }
            } );
        });
    } else {
        $('body').on('mouseenter', '.optin-elem.deactivated', function(e) {
            $(this).find('.infobox.optin').fadeIn(300).addClass('active');
            $(this).addClass('allow-overflow');
            $(this).closest('.embedded-content').addClass('allow-overflow');
            $(this).closest('.embed-responsive').addClass('allow-overflow');
        }).on('mouseleave', '.optin-elem.deactivated .infobox', function (e) {
            $(this).fadeOut(300).removeClass('active');
            $(this).closest('.optin-elem').removeClass('allow-overflow');
            $(this).closest('.embedded-content').removeClass('allow-overflow');
            $(this).closest('.embed-responsive').removeClass('allow-overflow');
        });
    }

    $('body').on('click', '.activate-optin-content', function(e){
        e.preventDefault();
        activateOptinContent($(this).closest('.optin-elem'));
        $(this).closest('.infobox').remove();
    });

    $('body').on('click', '.embedded-inline a.single-lg-item-video', function(e){
        $('body').append('<div class="hidden" id="videoid" data-videoid="'+$(this).data('videoid')+'"></div>');
    });
});
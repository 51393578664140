import Cookies from './plugins/js.cookie';
window.Cookies = Cookies;

//console.log(jQuery().jquery);

/* Settings icons for leaflet */
import L from './plugins/leaflet';

import Lazy from './plugins/jquery.lazy-master/jquery.lazy';

import markericon from '../images/plugins/leaflet/marker-icon.png';
import markericon2x from '../images/plugins/leaflet/marker-icon-2x.png';
import markershadow from '../images/plugins/leaflet/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: markericon,
    iconRetinaUrl: markericon2x,
    shadowUrl: markershadow
});

L.Marker.prototype.options.icon = DefaultIcon;
/* Settings icons for leaflet */

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(searchString, position) {
        var subjectString = this.toString();
        if (typeof position !== 'number' || !isFinite(position)
            || Math.floor(position) !== position || position > subjectString.length) {
            position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.indexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}

var url = window.location.href;
var urlArr = url.split("/");
var curDomain =  urlArr[2];
var curProtocol = urlArr[0] + '//';

var grid = Math.ceil(parseInt($('html').css('font-size')) / 2);


var lazyDefaultSettings = {
    // callback
    beforeLoad: function(element) {
        element.addClass('lazy-loaded');
    }
};

if (location.hash) {
    setTimeout(function() {
        window.scrollTo(0, 0);
        var scrolltop = $(location.hash).first().offset().top - $('.navbar-fixed-top').height() - 30;
        $("html, body").animate({ scrollTop: scrolltop }, 300);
    }, 1);
}

$.fn.isOnScreen = function(){

    var win = $(window);

    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

};

function carouselNextSlide(event){
    var elem = $(event.target).closest('.carousel');
    elem.carousel("next");
}

function carouselPrevSlide(event){
    var elem = $(event.target).closest('.carousel');
    elem.carousel("prev");
}

function initOverflowBoxes(){
    $('.overflow-box').each(function(){
        if( $(this).width() != $(this).closest('body').width() ) {
            $(this).css('width', 'auto').css('left', 'auto');
            var offset = $(this).offset();
            $(this).width($(this).closest('body').width()).css('left', '-' + offset.left + 'px');
        }
        if($(this).find('.img-responsive:not(.img-fixed-size-container)').length > 0) {
            adjustResponsiveImageToVerticalGrid($(this).find('.img-responsive:not(.img-fixed-size-container)'));
        }
    });
}

function adjustImagesToVerticalGrid(){
    $('.overflow-img').each(function(){
        if( $(this).width() != $(this).closest('body').width() ) {
            $(this).css('width', 'auto').css('left', 'auto');
            var offset = $(this).offset();
            $(this).width($(this).closest('body').width()).css('left', '-' + offset.left + 'px');
        }
        if($(this).find('.img-responsive:not(.img-fixed-size-container)').length > 0) {
            adjustResponsiveImageToVerticalGrid($(this).find('.img-responsive:not(.img-fixed-size-container)'));
        }
    });

    $('.img-responsive.img-fixed-size-container').each(function(){
        $(this).css('padding-top', '');
        var imgHeight = parseInt($(this).css('padding-top'));
        var mod = imgHeight % grid;

        if ( mod <= ( grid / 2 ) ){
            var newHeight = parseInt( imgHeight - mod );
            $(this).css( 'padding-top', newHeight+'px' );
        }
        else if ( mod > ( grid / 2 ) ){
            var newHeight = parseInt( imgHeight + (grid - mod) );
            $(this).css( 'padding-top', newHeight+'px' );
        }
    });

    $('.embed-responsive').each(function(){
        $(this).css('padding-bottom', '');
        var imgHeight = parseInt($(this).css('padding-bottom'));
        var mod = imgHeight % grid;

        if ( mod <= ( grid / 2 ) ){
            var newHeight = parseInt( imgHeight - mod );
            $(this).css( 'padding-bottom', newHeight+'px' );
        }
        else if ( mod > ( grid / 2 ) ){
            var newHeight = parseInt( imgHeight + (grid - mod) );
            $(this).css( 'padding-bottom', newHeight+'px' );
        }
    });
}

function adjustResponsiveImageToVerticalGrid(image){
    image.css('height', 'auto');
    var imgHeight = parseInt(image.height());
    var mod = imgHeight % grid;

    if ( mod <= ( grid / 2 ) ){
        var newHeight = parseInt( imgHeight - mod );
        image.height( newHeight );
    }
    else if ( mod > ( grid / 2 ) ){
        var newHeight = parseInt( imgHeight + (grid - mod) );
        image.height( newHeight );
    }
}

function centerCaptionInHeaderBox(){
    $('.header-img-size-box .center-in-header').each(function(){
        var parentElem = $(this).closest('.header-img-size-box');

        parentElem.find('.item').each(function(){
            $(this).addClass('tmpVisible');
        });

        var captionElem = $(this);
        captionElem.css('padding', 0);
        captionElem.css('bottom', 'auto');
        var totalHeight = parseInt(parentElem.outerHeight());
        var captionHeight = parseInt(captionElem.outerHeight());
        var bottomPosition = Math.floor( ( totalHeight - captionHeight ) / 2 );

        captionElem.css( 'top', bottomPosition + 'px' );
        captionElem.fadeIn(200);

        parentElem.find('.item').each(function(){
            $(this).removeClass('tmpVisible');
        });
    });
}

var yPosition = 0;

function noscroll() {
    window.scrollTo( 0, yPosition );
}

function reInitScrollPane(){
    $('#navbar-collapse .pane-dropdown.jspScrollable').each(function(){
        var paneElem = $(this);
        var parentLiElem = paneElem.closest('li.dropdown');
        var toggler = parentLiElem.find('.dropdown-toggle');
        var paneApi = paneElem.data('jsp');
        paneApi.destroy();
        toggler.trigger('click').trigger('click');
    });
}

$('.img-responsive:not(.img-fixed-size-container)').on('load', function(){
    adjustResponsiveImageToVerticalGrid($(this));
});

function supports_video() {
    return !!document.createElement('video').canPlayType;
}

/* Recaptcha START */
/*
function formSubmit(response) {
    $('#recaptcha-form').submit();
}
*/
/* Recaptcha END */

function getViewport() {
    var win = $(window);
    var vpW=win.width();
    return {
        l: win.scrollLeft(),
        t: win.scrollTop(),
        w: vpW,
        h: win.height()
    }
}

function getExcactWidth(elem){
    var rect = elem[0].getBoundingClientRect();
    var width;
    if (rect.width) {
        // `width` is available for IE9+
        width = rect.width;
    } else {
        // Calculate width for IE8 and below
        width = rect.right - rect.left;
    }
    width = width - parseInt(elem.css('padding-left')) - parseInt(elem.css('padding-right'));

    return width;
}

function getSpecialGridWidth(elem){
    elem.append('<div id="tmp-container" class="container line-content"><div class="row"><div id="tmp-col" class="col-lg-4 col-md-4 col-sm-6 col-xs-6"></div></div></div>');
    var widthThird = getExcactWidth($('#tmp-col'));

    $('#tmp-col').attr('class', 'col-lg-4 col-md-4 col-sm-6 col-xs-6');
    var widthHalf = getExcactWidth($('#tmp-col'));

    $('#tmp-col').attr('class', 'col-lg-3 col-md-3 col-sm-6 col-xs-6');
    var widthFourth = getExcactWidth($('#tmp-col'));

    var widthFull = getExcactWidth($('#tmp-container'));

    $('#tmp-container').remove();

    var widthObj = {
        widthThird: widthThird,
        widthHalf: widthHalf,
        widthFourth: widthFourth,
        widthFull: widthFull
    }

    return widthObj;
}

function adjustToGridWidth(){
    if($('.adjustToGridWidth').length > 0){
        $('body').append('<div id="tmp-container" class="container line-content"><div class="row"><div id="tmp-col" class="col-lg-4 col-md-4 col-sm-6 col-xs-6"></div></div></div>');
        var widthThird = getExcactWidth($('#tmp-col'));

        $('#tmp-col').attr('class', 'col-lg-4 col-md-4 col-sm-6 col-xs-6');
        var widthHalf = getExcactWidth($('#tmp-col'));

        $('#tmp-col').attr('class', 'col-lg-3 col-md-3 col-sm-6 col-xs-6');
        var widthFourth = getExcactWidth($('#tmp-col'));

        var widthFull = getExcactWidth($('#tmp-container'));

        $('#tmp-container').remove();

        $('.adjustToGridWidth').each(function(){
            var elem = $(this);

            if(elem.closest('.infobox').length > 0){
                var specialGridWidth = getSpecialGridWidth(elem.closest('.infobox'));
                if(elem.hasClass('half')){
                    elem.width(specialGridWidth.widthHalf);
                    elem.find('.tweet-container, .fb-post').attr("data-width", Math.floor(specialGridWidth.widthHalf));
                }
                else if(elem.hasClass('third')){
                    elem.width(specialGridWidth.widthThird);
                    elem.find('.tweet-container, .fb-post').attr("data-width", Math.floor(specialGridWidth.widthThird));
                }
                else if(elem.hasClass('fourth')){
                    elem.width(specialGridWidth.widthFourth);
                    elem.find('.tweet-container, .fb-post').attr("data-width", Math.floor(specialGridWidth.widthFourth));
                }
                else {
                    elem.find('.tweet-container, .fb-post').attr("data-width", Math.floor(specialGridWidth.widthFull));
                }
            }
            else {
                if(elem.hasClass('half')){
                    elem.width(widthHalf);
                    elem.find('.tweet-container, .fb-post').attr("data-width", Math.floor(widthHalf));
                }
                else if(elem.hasClass('third')){
                    elem.width(widthThird);
                    elem.find('.tweet-container, .fb-post').attr("data-width", Math.floor(widthThird));
                }
                else if(elem.hasClass('fourth')){
                    elem.width(widthFourth);
                    elem.find('.tweet-container, .fb-post').attr("data-width", Math.floor(widthFourth));
                }
                else {
                    elem.find('.tweet-container, .fb-post').attr("data-width", Math.floor(widthFull));
                }
            }
        });
    }
}

function buildGridDisplay(){
    if($('.grid-display').length > 0){
        $('.grid-display').data('grid-id', 0).removeClass('reset').removeClass('cleared');
        $('.grid-display-wrapper').removeClass('has-full-width');

        $(':not(.grid-display) + .grid-display, * > .grid-display:first-of-type').each(function(){
            if(!($(this).parent().hasClass('grid-display-wrapper'))){
                $(this).
                nextUntil(':not(.grid-display)').
                addBack().
                wrapAll('<div class="grid-display-wrapper" />');
            }
        });

        var gridIDCounter = 1;
        $('.grid-display').each(function(){
            var gridElem = $(this);
            if(gridElem.data('grid-id') == 0){
                if(gridElem.parent().find('.grid-display').length > 1){
                    gridElem.parent().find('.grid-display').data('grid-id', gridIDCounter);
                    gridIDCounter = gridIDCounter + 1;
                    var cols = Math.floor(gridElem.parent().width() / gridElem.width());
                    gridElem.parent().find('.grid-display:nth-of-type('+cols+'n)').addClass('reset');
                    gridElem.parent().find('.grid-display:nth-of-type('+cols+'n+1)').addClass('cleared');

                    if(gridElem.closest('.grid-display-wrapper').children('.grid-display').length >= cols){
                        gridElem.closest('.grid-display-wrapper').addClass('has-full-width');
                    }
                }
            }
        });
    }
}

function resizeEmbeddedContent(){
    if (typeof FB !== 'undefined') {
        FB.XFBML.parse();
    }
    if (typeof twttr !== 'undefined') {
        twttr.widgets.load();
    }
}

function activateLoadingBoxes(){
    $('.loading-embedded-content').closest('.embedded-content').addClass('embedded-content-loading');
    $('.loading-embedded-content').removeClass('deactivated');
}

function fadeinElems(){
    $('.fadein').addClass('loaded');
}

function resizeNavbarMenu(){
    if($('#navbar-collapse:visible').length > 0 && $('.navbar-fixed-top .navbar-toggle:visible').length == 0) {
        $('nav.navbar-fixed-top:not(.trans)').addClass('trans');
        setTimeout(function() {
                $('nav.navbar-fixed-top.scrolled').addClass('reset-scrolled').removeClass('scrolled');

                if ($('nav.navbar > .container').length > 0) {
                    var availbaleWidth = $('nav.navbar > .container').innerWidth();
                }
                else {
                    var availbaleWidth = $('nav.navbar > .container-fluid').innerWidth();
                }
                $('.navbar-brand').addClass('notransition');
                $('.navbar-brand img').addClass('notransition');
                $('.navbar-brand').addClass('original-size');
                var logoWidth = $('.navbar-brand').outerWidth();
                $('.navbar-brand').removeClass('original-size');
                $('.navbar-brand').removeClass('notransition');
                $('.navbar-brand img').removeClass('notransition');

                var featureBoxWidth = 0;
                if ($('#navbar-feature-box').length > 0) {
                    var featureBoxWidth = $('#navbar-feature-box').outerWidth() + parseInt($('#navbar-feature-box').css('margin-right'));
                }

                var menuWidth = availbaleWidth - Math.ceil(logoWidth) - Math.ceil(featureBoxWidth) - 20 - parseInt($('ul.navbar-nav').css('margin-right'));

                $('ul.navbar-nav').css('width',menuWidth+'px');

                var liWidth = 0;
                var endofspace = false;
                $('ul.navbar-nav > li:not(.user-li)').each(function () {
                    if(endofspace){
                        $(this).hide();
                    }
                    else {
                        $(this).show();
                        var position = $(this).position();
                        if (position.top > 0) {
                            $(this).hide();
                            endofspace = true;
                        }
                        else {
                            liWidth = liWidth + Math.ceil($(this).outerWidth());
                        }
                    }
                });

                $('ul.navbar-nav').css('width','auto');

                $('nav.navbar-fixed-top.reset-scrolled').addClass('scrolled').removeClass('reset-scrolled');
                $('nav.navbar-fixed-top').removeClass('trans');
            }, 200
        );
    }
    else {
        $('ul.navbar-nav').css('width', 'auto');
        $('nav.navbar-fixed-top').removeClass('trans');
    }
}

function alignRightMobileNavFeatures(){
    if($('.navbar-fixed-top.full-width.justified-with-content-mobile').length > 0) {
        if($('.navbar-fixed-top .navbar-toggle:visible').length > 0) {
            $('.navbar-fixed-top > .container-fluid').removeClass('container-fluid').addClass('container');
            var containerElem = $('.navbar-fixed-top > .container');
        }
        else {
            $('.navbar-fixed-top > .container').removeClass('container').addClass('container-fluid');
            $('.navbar-fixed-top > .container-fluid').css('margin-right', '').css('width', '').css('max-width', '');
            var containerElem = false;
        }
    }
    else {
        var containerElem = $('.navbar-fixed-top > .container');
    }

    if(containerElem){
        containerElem.css('margin-right', '').css('width', '').css('max-width', '');
        if($('.navbar-fixed-top .navbar-toggle:visible').length > 0) {
            var curWidth = containerElem.innerWidth();
            var curMarginRight = parseInt(containerElem.css('margin-right'));
            if (curMarginRight > 0) {
                var newWidth = curWidth + curMarginRight;
                containerElem.css('width', newWidth + 'px').css('margin-right', 0).css('max-width', 'none');
            }
        }
    }

}

function getAdhocNotification(){
    if($('#adhoc-notification').length > 0){
        $.ajax({
            url: '/adhoc_notification',
            success: function(data){
                var jquery_data = $(data);

                if( jquery_data.find('#adhoc-notification').length > 0 ){

                    var adhocID = jquery_data.find('#adhoc-notification').data('adhoc-id');
                    var cookieName = 'Notification_' + adhocID;
                    //window.cookieconsent.utils.setCookie(cookieName, 'disabled', 3650, );
                    if(Cookies.get(cookieName) != 'disabled'){
                        $('#adhoc-notification').html(jquery_data.find('#adhoc-notification').html()).slideDown(500,function(){$('#adhoc-notification #close-btn').fadeIn(300);}).data('adhoc-id',adhocID);
                    }
                }
            }
        });
    }

    return false;
}

function adjustCookieBannerPadding(){
    if($('.cc-theme-custom').length > 0){
        $('footer').css('padding-bottom', $('.cc-theme-custom').outerHeight()+'px');
        $('#page-top-link').css('margin-bottom', $('.cc-theme-custom').outerHeight()+'px');
        if($('.navbar-fixed-top .navbar-toggle:visible').length > 0) {
            $('#navbar-collapse').css('padding-bottom', $('.cc-theme-custom').outerHeight()+'px');
        }
        else {
            $('#navbar-collapse').css('padding-bottom', '0');
        }
    }
}

function adjustToFullWidth(){
    $('.adjust-to-full-width').each(function(){
        var offset = $(this).offset();
        var left = '-' + offset.left + 'px';
        $(this).css('left', left).width(getViewport().w);
    });
}

function setHiddenRealSizeElems(){
    $('.hidden-real-size-box').each(function(){
        var outerHeight = $(this).children().first().outerHeight();
        var marginTop = parseInt($(this).children().first().css('margin-top'));
        var marginBottom = parseInt($(this).children().first().css('margin-bottom'));
        var completeHeight = outerHeight + marginTop + marginBottom;
        $(this).css('margin-bottom', '-'+completeHeight+'px');
    });
};

function showEventFilter(){
    $('#event-filter-btn').removeClass('hidden');
    if(!$('#event-filter-btn').hasClass('info-activated')){
        var width = $('#event-filter-btn').find('i').outerWidth() + $('#event-filter-btn').find('span').outerWidth();
        $('#event-filter-btn').addClass('fixed').addClass('wait').width(width);
        setTimeout(function(){
            $('#event-filter-btn').removeClass('fixed').removeClass('wait');
            var width = $('#event-filter-btn').find('i').outerWidth();
            $('#event-filter-btn').addClass('fixed').width(width);
        }, 2000);
        $('#event-filter-btn').addClass('info-activated');
    }
    else {
        var width = $('#event-filter-btn').find('i').outerWidth();
        $('#event-filter-btn:not(.wait)').width(width);
    }
}

function hideEventFilter(){
    $('#event-filter-btn').addClass('hidden');
}

function activateOverlay(classes){
    if (typeof classes == 'string') {
        $('body').append('<div id="overlay-content" class="' + classes + '"></div>');
    }
    else{
        $('body').append('<div id="overlay-content"></div>');
    }
    setTimeout(function() {
        $('#overlay-content').addClass('colored');
    }, 100);
}

function deactivateOverlay(){
    $('#overlay-content').removeClass('colored');
    setTimeout(function() {
        $('#overlay-content').remove();
    }, 300);
}

function activateScrollLock(){
    if ($(document).height() > $(window).height()) {
        var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
        $('html').addClass('noscroll').css('top',-scrollTop);
    }
    yPosition = window.pageYOffset;
    $('body').addClass('scroll-lock');
    window.addEventListener('scroll', noscroll);
}

function deactivateScrollLock(){
    var scrollTop = parseInt($('html').css('top'));
    $('html').removeClass('noscroll');
    $('html,body').scrollTop(-scrollTop);
    $('body').removeClass('scroll-lock');
    window.removeEventListener('scroll', noscroll);
}

function showEventFilterCanvas(){
    //hideEventFilter();
    var width = $('#event-filter-btn').find('i').outerWidth();
    $('#event-filter-btn').width(width);
    activateScrollLock();
    activateOverlay('over-nav event-filter');
    $('#event-filter-canvas').addClass('opened');
}

function hideEventFilterCanvas(){
    //showEventFilter();
    deactivateScrollLock();
    deactivateOverlay();
    $('#event-filter-canvas').removeClass('opened');
}

function updateEventFilters(){
    $('#event-filter-canvas').addClass('loading');
    $('#overlay-content').addClass('loading');
    
    var form = $('#event-search-form');
    var url = form.attr( 'action' );

    var formData = form.serialize().replace(new RegExp('%5B%5D', 'g'), '[]');

    var filterSet = false;

    if(!form.find('input[name="s"]').val()){
        formData = formData.replace('s=&', '');
        formData = formData.replace('s=', '');
    }
    else {
        filterSet = true;
    }

    if(!form.find('select[name="m"]').val()){
        formData = formData.replace('m=&', '');
        formData = formData.replace('m=', '');
    }
    else {
        filterSet = true;
    }

    if(formData.indexOf('c[]') != -1){
        filterSet = true;
    }

    if(formData.slice(-1) == '&'){
        formData = formData.substring(0, formData.length - 1);
    }

    $.ajax({
        url: url,
        type: 'GET',
        data: formData + '&method=ajax',
        cache: false,
        contentType: false,
        processData: false,
        success: function(data){
            var jquery_data = $(data);
            form.find('select[name="m"]').html(jquery_data.find('#event-search-form select[name="m"]').html());
            form.find('#event-category-filter').html(jquery_data.find('#event-category-filter').html());
            if(filterSet){
                $('#reset-filter-btn').removeClass('disabled').prop("disabled",false);
            }
            else {
                $('#reset-filter-btn').addClass('disabled').prop("disabled",true);
            }
            $('#submit-btn-count').text(jquery_data.find('#submit-btn-count').text());

            if( jquery_data.find('#loadMoreBtn').length > 0 ){
                if($('#loadMoreBtn').length > 0){
                    $('#loadMoreBtn').html(jquery_data.find('#loadMoreBtn').html());
                    $('#loadMoreBtn').removeClass('loading').show();
                }
                else {
                    $('.line-content').append('<div id="loadMoreBtn">' + jquery_data.find('#loadMoreBtn').html() + '</div>');
                    $('#loadMoreBtn').show();
                }
            }
            else {
                $('#loadMoreBtn').hide();
            }
            $('#content-section .ajax-content').html(jquery_data.find('.ajax-content').html());
            deactivateScrollLock();
            //deactivateOverlay();
            $('#overlay-content').removeClass('loading');
            var scrolltop = $('.line-content').first().offset().top - $('.navbar-fixed-top').height() - 30;

            var historyURL = curProtocol + curDomain + url;
            history.pushState(null, null, historyURL);

            $("html, body").animate({ scrollTop: scrolltop }, 300, function(){
                activateScrollLock();
                $('.lazy-loading').lazy(lazyDefaultSettings);
            });

            $('#event-filter-canvas').removeClass('loading');
        }
    });
}

function checkEventFilterHeight(){
    var viewPortHeight = getViewport().h;
    var filterHeight = parseInt($('#event-filter-canvas').css('padding-top')) + $('#event-search-form').outerHeight();

    if(viewPortHeight < filterHeight){
        $('#event-filter-wrapper').addClass('scrollable');
    }
    else {
        $('#event-filter-wrapper').removeClass('scrollable');
    }
}

$(document).ready(function() {

    $('.captcha_image').each(function(){
        $(this).addClass('img-rounded').addClass('img-responsive');
        var src = $(this).data('path') + '?n=' + (new Date()).getTime();
        $(this).attr('src',src);
        $(this).on('load', function(){
            $(this).removeClass('loading');
            adjustResponsiveImageToVerticalGrid($(this));
        });
    });

    adjustToGridWidth();
    buildGridDisplay();
    resizeNavbarMenu();
    alignRightMobileNavFeatures();
    getAdhocNotification();
    //adjustToFullWidth();
    checkEventFilterHeight();

    $('.img-responsive:not(.img-fixed-size-container)').each(function(){
        var image = $(this);
        if (image[0].complete) {
            adjustResponsiveImageToVerticalGrid(image);
        }
    });

    //$('nav.navbar-fixed-top').hide();

    setHiddenRealSizeElems();

    setTimeout(function(){
        //$('nav.navbar-fixed-top').fadeIn();
        centerCaptionInHeaderBox();
        activateLoadingBoxes();
        fadeinElems();
    }, 200);

    /* Lazy loading */
    $('.lazy-loading').lazy(lazyDefaultSettings);

    if($('.initScrollElem').length > 0){
        var scrolltop = $('.initScrollElem').first().offset().top - $('.navbar-fixed-top').height() - 30;
        $("html, body").animate({ scrollTop: scrolltop }, 300);
    }

    if(!(supports_video)){
        $('.background-delay').removeClass('background-delay');
    }
    else {
        if($('.background-delay video').length > 0) {
            setTimeout(function () {
                $('.background-delay video').get(0).play();
            }, 300);
            $('.background-delay video').click(function () {
                $(this).get(0).play();
            });
        }
    }

    if ( $('.back-button-navbar:visible').length > 0 ){
        $('body').addClass( 'has-back-button' );
    }

    $('body').addClass( 'loaded' );

    initOverflowBoxes();
    adjustImagesToVerticalGrid();

    if(parseInt($(window).scrollTop()) > 0){
        $('nav.navbar-fixed-top').addClass('scrolled').addClass('navbar-brand-scroll');
        $('.back-button-navbar').addClass('scrolled');
        $('body').addClass('scrolled');
    }

    if(parseInt($(window).scrollTop()) > 100){
        $('#page-top-link').removeClass('hidden');
    }

    var position = $(window).scrollTop();
    $(window).scroll(function(){
        if(!$('body').hasClass('scroll-lock')) {
            var scroll = $(window).scrollTop();
            if (scroll > 0) {
                $('nav').addClass('scrolled');
                $('nav').addClass('navbar-brand-scroll');
                $('.navbar-fixed-top').addClass('scrolled');
                $('.back-button-navbar').addClass('scrolled');
                $('body').addClass('scrolled');
            }
            if (scroll <= 0 && $('.navbar-header button').hasClass('collapsed')) {
                $('nav').removeClass('scrolled');
                $('nav').removeClass('navbar-brand-scroll');
                $('.navbar-fixed-top').removeClass('scrolled');
                $('.back-button-navbar').removeClass('scrolled');
                $('body').removeClass('scrolled');
            }

            if (scroll > 100) {
                $('#page-top-link.hidden').removeClass('hidden');
            }

            if (scroll <= 100 && !($('#page-top-link').hasClass('hidden'))) {
                $('#page-top-link').addClass('hidden');
            }

            // Nach unten scrollen
            if (scroll > position) {
                if (scroll > 100) {
                    if (!$('nav.navbar-fixed-top .collapse').hasClass('in')) {
                        $('nav.navbar-fixed-top').addClass('fadeAway');
                        $('.back-button-navbar').addClass('fadeAway');
                    }
                }
            }
            // Nach oben scrollen
            else {
                $('nav.navbar-fixed-top').removeClass('fadeAway');
                $('.back-button-navbar').removeClass('fadeAway');
            }
            position = scroll;
        }

    });

    $('.carousel').each(function(){
        var touchControl = new Hammer($(this)[0]);
        touchControl.on("swiperight", carouselPrevSlide );
        touchControl.on("swipeleft", carouselNextSlide );
    });

    $('body').on( 'click', '.navbar-toggle.collapsed', function(){
        $('#navbar-collapse').addClass('slideIn').removeClass('slideOut');
        $('nav.navbar-fixed-top').addClass('scrolled').addClass('navbar-brand-scroll');

        activateOverlay('menu-overlay');
        activateScrollLock();
    } );

    $('body').on( 'click', '.navbar-toggle:not(.collapsed)', function(){
        $('#navbar-collapse').removeClass('slideIn').addClass('slideOut');
        var scrollTop = parseInt($('html').css('top'));
        if(scrollTop == 0){
            $('nav.navbar-fixed-top').removeClass('scrolled').removeClass('navbar-brand-scroll');
        }
        deactivateScrollLock();
        deactivateOverlay();

    } );
    $('body').on( 'click', '#overlay-content.menu-overlay', function(){
        $('.navbar-toggle:not(.collapsed)').trigger('click');
    });

    $('.lightgallery.carousel').lightGallery(
        {
            selector: '.item'
        }
    );
    $('iframe.dynamic-height').iFrameResize();

    /* Lightboxen */
    $('.single-lg-item').each(function(){
        $(this).lightGallery(
            {
                selector: 'this',
                iframeMaxWidth: '80%'
            }
        );
        $(this).on('onAfterAppendSubHtml.lg', function(event, index){
            $(document).on( 'click', '.lg-sub-html [data-toggle="collapse"]', function(){
                if($(this).hasClass('less-info')){
                    $(this).find('span').text($(this).attr('data-show-text'));
                    $(this).find('.fa').removeClass('fa-chevron-circle-up').addClass('fa-chevron-circle-down');
                }
                else {
                    $(this).find('span').text($(this).attr('data-hide-text'));
                    $(this).find('.fa').removeClass('fa-chevron-circle-down').addClass('fa-chevron-circle-up');
                }
                $(this).toggleClass('less-info');
                $(this).closest('.lg').find('.collapse').collapse("toggle");
            } );
        });
    });


    // Back-Button URL anpassen
    if( $('.back-button').length > 0 ){
        var ReferrerSplit = document.referrer.split( '?' );

        if( ReferrerSplit[0].startsWith( window.location.origin ) &&
            ReferrerSplit[0].endsWith( $('.back-button a').attr( 'href' ) ) ){
            $('.back-button a').attr( 'href', document.referrer );
        }
    }

    // Load more button
    $('body').on( 'click', '#loadMoreBtn:not(.loading,.nGY2GalleryMoreButton) a', function(e){
        e.preventDefault();
        $('#loadMoreBtn').addClass('loading');
        var icon = $(this).find('i');
        icon.attr('class', icon.attr('data-loading-class'));
        var url = $(this).attr( 'data-href' );
        var dataObj = {
            offset: $(this).attr( 'data-offset' ),
            method: 'ajax'
        }

        if($('#event-search-form input[name="s"]').val()){
            dataObj.s = $('#event-search-form input[name="s"]').val();
        }

        if($('#event-search-form select[name="m"] option:selected').length > 0){
            dataObj.m = $('#event-search-form select[name="m"]').val();
        }

        if($('#event-search-form input[name="c[]"]:checked').length > 0){
            dataObj.c = [];
            $('#event-search-form input[name="c[]"]:checked').each(function() {
                dataObj.c.push($(this).val());
            });
        }
        if($(this).attr( 'data-phrase' )){
            dataObj.phrase = $(this).attr( 'data-phrase' );
        }

        $.ajax({
            url: url,
            data: dataObj,
            success: function(data){
                var jquery_data = $(data);

                if( jquery_data.find('#loadMoreBtn').length > 0 ){
                    $('#loadMoreBtn').html(jquery_data.find('#loadMoreBtn').html());
                    $('#loadMoreBtn').removeClass('loading');
                }
                else {
                    $('#loadMoreBtn').hide();
                }
                $('#content-section .ajax-content').append(jquery_data.find('.ajax-content').html());
                $('.lazy-loading').lazy(lazyDefaultSettings);

                initOverflowBoxes();
                adjustImagesToVerticalGrid();
            }
        });
    });

    /* Top Menu START */
    $('body').on( 'click', '.dropdown-submenu a.toggle-menu', function(e){
        var parent = $(this).closest('.dropdown-submenu');
        if(!$('#navbar-collapse').hasClass('in')) {
            parent.siblings('.dropdown-submenu.open').removeClass('open').children('ul').hide();
        }
        $(this).next('ul').toggle();
        parent.toggleClass('open');
        if(!$('#navbar-collapse').hasClass('in')) {
            if (parent.hasClass('open')) {
                var paneElem = $(this).closest('.pane-dropdown');
                var paneApi = paneElem.data('jsp');
                paneApi.scrollToElement(parent.find('li.last'), false);
            }
        }
        e.stopPropagation();
        e.preventDefault();
    });

    $("#navbar-collapse li.dropdown").on("hide.bs.dropdown", function(){
        $(this).find('.pane-dropdown').css('display', 'none');
    });

    $("#navbar-collapse li.dropdown .pane-dropdown").on("mouseleave", function(){
        if(!$('#navbar-collapse').hasClass('in')) {
            deactivateScrollLock();
        }
    });

    $("#navbar-collapse li.dropdown .pane-dropdown").on("mouseenter", function(){
        if(!$('#navbar-collapse').hasClass('in')) {
            activateScrollLock();
        }
    });

    $('body').on( 'click', '#navbar-collapse:not(.in) a.toggle-menu, #navbar-collapse:not(.in) a.dropdown-toggle', function(){
        var parentLiElem = $(this).closest('li.dropdown');
        var parentUlElem = $(this).closest('li.dropdown').find('.pane-dropdown');
        if(parentUlElem.length > 0) {
            if (parentLiElem.hasClass('open') && $(this).hasClass('dropdown-toggle')) {
                parentUlElem.hide();
                parentLiElem.find('.dropdown-menu.menu_level_1').hide();
            } else {
                parentLiElem.find('.dropdown-menu.menu_level_1').show();

                var availableHeight = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) - $('.navbar-fixed-top').height();

                if ($(this).hasClass('toggle-menu')) {
                    var ulHeight = parentLiElem.find('.dropdown-menu.menu_level_1').height();
                    if (ulHeight > availableHeight) {
                        var paneBoxHeight = availableHeight;
                    } else {
                        var paneBoxHeight = ulHeight;
                    }
                    parentUlElem.find('.jspContainer').height(paneBoxHeight);
                    parentUlElem.find('.jspVerticalBar .jspTrack').height(paneBoxHeight);
                }

                var availableWidth = parentUlElem.show().find('.dropdown-menu.menu_level_1').width() + 5;
                var paneElem = parentUlElem.css('max-height', availableHeight + 'px').width(availableWidth).show().jScrollPane({
                    showArrows: false,
                    //autoReinitialise: true,
                    verticalGutter: 0
                });

                var paneApi = paneElem.data('jsp');
                if (!(paneApi.getIsScrollableV())) {
                    var availableWidth = availableWidth - 5;
                    paneElem.width(availableWidth);
                }
            }
        }
    });
    /* Top Menu END */

    /* Recaptcha START */
    /*
    $('#recaptcha-form').submit(function (event) {
        if (!grecaptcha.getResponse()) {
            event.preventDefault(); //prevent form submit
            grecaptcha.execute();
        }
    });
    */
    /* Recaptcha END */

    /* Nano Gallery START */
    var vpW = getViewport().w;
    if (vpW >= 1200){
        var bpointID = 'XL';
    }
    else if (vpW >= 992){
        var bpointID = 'LA';
    }
    else if (vpW >= 768){
        var bpointID = 'ME';
    }
    else if (vpW >= 480){
        var bpointID = 'SM';
    }
    else {
        var bpointID = 'XS';
    }

    $("#nanoGallery .gallery-thumb-img").each(function(){
        $(this).attr( 'data-ngthumb', $(this).attr( 'data-ngthumb'+bpointID ) );
        $(this).attr( 'data-ngthumb2x', $(this).attr( 'data-ngthumb2x'+bpointID ) );
    });

    /*
    var nanoGallery = $("#nanoGallery").nanoGallery({
        thumbnailWidth: 'auto',
        //thumbnailHeight: '200 XS100 SM200 ME250 LA300 XL400',
        thumbnailHeight: '200 XS150 SM150 ME200 LA250 XL300',
        //thumbnailHeight: 500,
        breakpointSizeSM: 480,
        breakpointSizeME: 768,
        breakpointSizeLA: 992,
        breakpointSizeXL: 1200,
        colorScheme: 'none',
        thumbnailHoverEffect: [{ name: 'labelAppear75', duration: 300 }],
        thumbnailLabel: {
            position: 'overImageOnMiddle',
            display: true,
            displayDescription: false,
            titleMaxLength: 60,
            descriptionMaxLength: 120,
            hideIcons: true,
            align: 'center'
        },
        theme: 'kmu',
        thumbnailGutterWidth : 4,
        thumbnailGutterHeight : 4,
        thumbnailLazyLoad: true,
        thumbnailLazyLoadTreshold: 300,
        viewerToolbar: {
            style: 'fullWidth',
        },
        fnImgDisplayed: kmuImageDisplayed
    });
    */

    var thumbnailWidth = 'auto';
    var thumbnailHeight = '200 XS150 SM150 ME200 LA250 XL300';

    if($("#nanoGallery").hasClass('cascading')){
        var thumbnailWidth = '300 XS250 SM250 ME300 LA350 XL400';
        var thumbnailHeight = 'auto';
    }
    else if($("#nanoGallery").hasClass('grid')){
        var thumbnailWidth = '200 XS150 SM150 ME200 LA250 XL300';
        var thumbnailHeight = '200 XS150 SM150 ME200 LA250 XL300';
    }

    if($("#nanoGallery").length > 0){
        $("#nanoGallery").after('<div id="lightgallery" class="lightgallery-style hidden"></div>');
        $("#nanoGallery").find('.lg-item').each(function(){
            var lgElem = $(this);
            $(this).remove();
            $('#lightgallery').append(lgElem);
        });
    }

    var nanoGallery = $("#nanoGallery").nanogallery2({
        galleryDisplayMode: 'moreButton',
        galleryDisplayMoreStep: 4,
        icons: {
            galleryMoreButton: '<i class="fa fa-plus-square" data-loading-class="fa fa-spinner fa-spin" aria-hidden="true"></i>Mehr anzeigen'
        },
        thumbnailHeight: thumbnailHeight,
        thumbnailWidth: thumbnailWidth,
        thumbnailOpenImage: true,
        breakpointSizeSM: 480,
        breakpointSizeME: 768,
        breakpointSizeLA: 992,
        breakpointSizeXL: 1200,
        thumbnailBorderHorizontal: 0,
        thumbnailBorderVertical: 0,
        // thumbnailHoverEffect2 funktioniert im Edge nicht
        //thumbnailHoverEffect2: 'labelAppear75',
        thumbnailHoverEffect2: 'label_opacity_1%_100%',
        thumbnailLabel: {
            position: 'overImageOnMiddle',
            display: true,
            displayDescription: false,
            titleMaxLength: 60,
            descriptionMaxLength: 120,
            hideIcons: true,
            align: 'center'
        },
        thumbnailGutterWidth : 4,
        thumbnailGutterHeight : 4,
        thumbnailLazyLoad: true,
        thumbnailLazyLoadTreshold: 300,
        viewerToolbar: {
            position: 'bottom',
            align: 'left',
            fullWidth: true,
            standard: 'minimizeButton,downloadButton,linkOriginalButton,fullscreenButton,label',
            minimized: 'minimizeButton'
        },
        allowHTMLinData: true,
        fnThumbnailOpen: initGaleryLightbox,
        fnGalleryRenderEnd: function(albumIndex){
            var $lg = $('#lightgallery');
            $lg.lightGallery({
                selector: '.lg-item'
            });
            $lg.on('onAfterAppendSubHtml.lg', function(event, index){
                $(document).on( 'click', '.lg-sub-html [data-toggle="collapse"]', function(){
                    if($(this).hasClass('less-info')){
                        $(this).find('span').text($(this).attr('data-show-text'));
                        $(this).find('.fa').removeClass('fa-chevron-circle-up').addClass('fa-chevron-circle-down');
                    }
                    else {
                        $(this).find('span').text($(this).attr('data-hide-text'));
                        $(this).find('.fa').removeClass('fa-chevron-circle-down').addClass('fa-chevron-circle-up');
                    }
                    $(this).toggleClass('less-info');
                    $(this).closest('.lg').find('.collapse').collapse("toggle");
                } );
            });
        },
        //fnImgDisplayed: kmuImageDisplayed
    });

    function initGaleryLightbox(items){
        var clickedItemIndex = items[0].mediaNumber - 1;
        $('#lightgallery').find('.lg-item:eq('+clickedItemIndex+')').trigger('click');
    }

    $("#nanoGallery").addClass('kmuTheme');

    /*
    function kmuImageDisplayed(param1){
        $('.nGY2Viewer .toolbarContainer').removeClass('show-desc');
        var customData = param1.customData;
        var ngViewer = $('.nGY2Viewer');
        var description = ngViewer.find('.description');
        var title = ngViewer.find('.title');
        if(description.find('para').size() > 0){
            title.append('<br /><a class="btn btn-primary desc-toggler" data-toggle="collapse" data-hide-text="'+customData.hideText+'" data-show-text="'+customData.showText+'"><i class="fa fa-chevron-circle-down" aria-hidden="true"></i><span>'+customData.showText+'</span></a>');
        }

        if($("#nanoGallery").nanogallery2('data').lightbox.toolbarMode == 'min'){
            $('.nGY2Viewer').addClass('toolbarMinimized');
        }
        else {
            $('.nGY2Viewer').addClass('toolbarMaximized');
        }

        return true;
    }
    */

    /*
    $('body').on( 'click', '.nGY2Viewer .desc-toggler', function() {
        $('.nGY2Viewer .toolbarContainer').toggleClass('show-desc');

        if($('.nGY2Viewer .toolbarContainer').hasClass('show-desc')){
            $(this).find('span').text($(this).attr('data-hide-text'));
            $(this).find('i').removeClass('fa-chevron-circle-down').addClass('fa-chevron-circle-up');
        }
        else {
            $(this).find('span').text($(this).attr('data-show-text'));
            $(this).find('i').removeClass('fa-chevron-circle-up').addClass('fa-chevron-circle-down');
        }

        $(window).trigger('resize');
    });
    */
    /* Nano Gallery END */

    /* Thumbnavi START */
    $('#aeCarousel').carousel({
        interval: false
    });

    $('.thumbnaviSlider .lightSlider').find('li').each(function(){
        $(this).width($(this).closest('.thumbnaviSlider').width());
    });

    $('.lightSlider').lightSlider({
        gallery:true,
        item:1,
        useCSS: true,
        loop:true,
        thumbItem:12,
        thumbMargin: 4,
        galleryMargin: 4,
        slideMargin:0,
        enableDrag: true,
        currentPagerPosition:'middle',
        prevHtml: '<span class="glyphicon glyphicon-chevron-left"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>',
        nextHtml: '<span class="glyphicon glyphicon-chevron-right"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>',
        onSliderLoad: function(el) {
            $('.lSSlideWrapper').css('padding-bottom', '');
            var imgHeight = parseInt($('.lSSlideWrapper').css('padding-bottom'));
            var mod = imgHeight % grid;

            if ( mod <= ( grid / 2 ) ){
                var newHeight = parseInt( imgHeight - mod );
                $('.lSSlideWrapper').css( 'padding-bottom', newHeight+'px' );
            }
            else if ( mod > ( grid / 2 ) ){
                var newHeight = parseInt( imgHeight + (grid - mod) );
                $('.lSSlideWrapper').css( 'padding-bottom', newHeight+'px' );
            }

            var paddingTop = Math.ceil( ($('.lSSlideWrapper').outerHeight() * $('.lSPager').find('li').first().width()) / $('.lSSlideWrapper').outerWidth() );
            $('.lSPager').find('li').css('padding-top', paddingTop+'px');

            el.lightGallery({
                selector: '.lightSlider .lslide'
            });

            el.on('onAfterAppendSubHtml.lg', function(event, index){
                $(document).on( 'click', '.lg-sub-html [data-toggle="collapse"]', function(){
                    if($(this).hasClass('less-info')){
                        $(this).find('span').text($(this).attr('data-show-text'));
                        $(this).find('.fa').removeClass('fa-chevron-circle-up').addClass('fa-chevron-circle-down');
                    }
                    else {
                        $(this).find('span').text($(this).attr('data-hide-text'));
                        $(this).find('.fa').removeClass('fa-chevron-circle-down').addClass('fa-chevron-circle-up');
                    }
                    $(this).toggleClass('less-info');
                    $(this).closest('.lg').find('.collapse').collapse("toggle");
                } );
            });

            $('.lSAction a').height(imgHeight);

            //Background images laden
            var thumbNaviWidth = el.closest('.lSSlideWrapper').width();
            var thumbWidth = el.closest('.lSSlideOuter').find('.lSPager li').first().width();
            var thumbCount = parseInt(Math.floor(thumbNaviWidth / thumbWidth)) + 1;
            for (var i = 1; i <= thumbCount; i++) {
                var img = el.find('li:nth-child(' + i + ') .pseudo-img.lazy-bg-item');
                if(img.length > 0) {
                    img.removeClass('lazy-bg-item');
                }
            }
            var img = el.find('li.lslide').last().find('.pseudo-img.lazy-bg-item');
            if(img.length > 0) {
                img.removeClass('lazy-bg-item');
            }

        },
        onBeforeSlide: function (slider, scene) {
            var sliderIndex = scene + 1;
            var curLi = slider.find('li:nth-child(' + sliderIndex + ')');
            var curSlideCount =  curLi.attr('data-slide-count');

            slider.closest('.lSSlideWrapper').find('li[data-slide-count='+curSlideCount+']').each(
                function(){
                    $(this).find('.pseudo-img.lazy-bg-item').removeClass('lazy-bg-item');
                }
            );
        },
        onAfterSlide: function (slider, scene) {
            var sliderIndex = scene + 1;
            if(scene == 0){
                var curLi = slider.find('li:nth-child(' + sliderIndex + ')');
                var prevSlideCount =  parseInt(curLi.attr('data-slide-count')) - 1;
                var nextSlideCount =  0;
            }
            else {
                var curLi = slider.find('li:nth-child(' + sliderIndex + ')');
                var prevSlideCount =  curLi.prev().attr('data-slide-count');
                var nextSlideCount =  curLi.next().attr('data-slide-count');
                if(typeof nextSlideCount === "undefined"){
                    nextSlideCount = 1;
                }
            }
            slider.closest('.lSSlideWrapper').find('li[data-slide-count='+prevSlideCount+']').each(
                function(){
                    $(this).find('.pseudo-img.lazy-bg-item').removeClass('lazy-bg-item');
                }
            );
            slider.closest('.lSSlideWrapper').find('li[data-slide-count='+nextSlideCount+']').each(
                function(){
                    $(this).find('.pseudo-img.lazy-bg-item').removeClass('lazy-bg-item');
                }
            );
        },
        responsive : [
            {
                breakpoint:1024,
                settings: {
                    thumbItem:10,
                }
            },
            {
                breakpoint:768,
                settings: {
                    thumbItem:8,
                }
            },
            {
                breakpoint:480,
                settings: {
                    thumbItem:6,
                }
            }
        ]
    });
    /* Thumbnavi END */

    /* SEARCH BOX */
    $('.header-search-box').on("show.bs.dropdown", function(event){
        setTimeout(function() {
            $(event.target).find('input.full-form-control').focus();
        }, 100);
    });

    /* Adhoc Notification */
    $('body').on('click', '#close-btn', function(){
        $(this).hide();
        var adhocID = $(this).closest('#adhoc-notification').data('adhoc-id');
        $(this).closest('#adhoc-notification').slideUp(500);
        var cookieName = 'Notification_' + adhocID;
        //window.cookieconsent.utils.setCookie(cookieName, 'disabled', 3650, );

        var secure = true;
        if(location.protocol == 'http:'){
            secure = false;
        }

        Cookies.set(cookieName, 'disabled', {
            expires: 3650,
            domain: curDomain,
            path: '/',
            secure: secure
        });
    });

    /* Hidden real size toggler */
    $('body').on('click', '.hidden-real-size-toggler', function(e){
        e.preventDefault();

        var boxElem = $(this).closest('.hidden-real-size-wrapper').find('.hidden-real-size-box');
        var toggler = $(this);

        if(!boxElem.hasClass('activated')){
            boxElem.hide().addClass('activated');
        }

        if(boxElem.is(':visible')){
            boxElem.slideUp(300,function(){
                toggler.find('.glyphicon, .link-text').toggleClass('hidden');
            });
        }
        else {
            boxElem.slideDown(500,function(){
                toggler.find('.glyphicon, .link-text').toggleClass('hidden');
            });
        }
    });

    /* Event calendar */
    if ($('#content-section.event-calendar .line-content').length > 0){
        if ($('#content-section.event-calendar .line-content').isOnScreen()) {
            showEventFilter();
        }
        else {
            hideEventFilter();
        }
    }


    $('body').on('mouseenter', '#event-filter-btn', function(){
        var width = $(this).find('i').outerWidth() + $(this).find('span').outerWidth();
        $(this).addClass('hover').width(width);
    }).on('mouseleave', '#event-filter-btn', function(){
        var width = $(this).find('i').outerWidth();
        $(this).removeClass('hover').width(width);
    }).on('click', '#event-filter-btn', function(e){
        e.preventDefault();
        showEventFilterCanvas();
    });

    $('body').on('click', '#event-filter-canvas-close-btn, #overlay-content.event-filter', function(e){
        e.preventDefault();
        $('#event-search-form').trigger('submit');
    });

    /* Event filter */
    // Load more button
    /*
    $('body').on( 'submit', '#event-search-form', function(e){
        e.preventDefault();

        $('#event-filter-canvas').removeClass('opened');
        $('#overlay-content').addClass('loading');

        var url = $(this).attr( 'action' );
        var formData = $(this).serialize().replace(new RegExp('%5B%5D', 'g'), '[]');
        var filterSet = false;

        if(!$(this).find('input[name="s"]').val()){
            formData = formData.replace('s=&', '');
            formData = formData.replace('s=', '');
        }
        else {
            filterSet = true;
        }

        if(!$(this).find('select[name="m"]').val()){
            formData = formData.replace('m=&', '');
            formData = formData.replace('m=', '');
        }
        else {
            filterSet = true;
        }

        if(formData.indexOf('c[]') != -1){
            filterSet = true;
        }

        if(formData.slice(-1) == '&'){
            formData = formData.substring(0, formData.length - 1);
        }

        $.ajax({
            url: url,
            type: 'GET',
            data: formData + '&method=ajax',
            cache: false,
            contentType: false,
            processData: false,
            success: function(data){
                var jquery_data = $(data);

                if( jquery_data.find('#loadMoreBtn').length > 0 ){
                    if($('#loadMoreBtn').length > 0){
                        $('#loadMoreBtn').html(jquery_data.find('#loadMoreBtn').html());
                        $('#loadMoreBtn').removeClass('loading').show();
                    }
                    else {
                        $('.line-content').append('<div id="loadMoreBtn">' + jquery_data.find('#loadMoreBtn').html() + '</div>');
                        $('#loadMoreBtn').show();
                    }
                }
                else {
                    $('#loadMoreBtn').hide();
                }
                $('#content-section .ajax-content').html(jquery_data.find('.ajax-content').html());
                $('#event-filter-canvas').html(jquery_data.find('#event-filter-canvas').html());

                deactivateScrollLock();
                deactivateOverlay();
                var scrolltop = $('.line-content').first().offset().top - $('.navbar-fixed-top').height() - 30;

                if(formData) {
                    var historyURL = curProtocol + curDomain + url + '?' + formData;
                }
                else {
                    var historyURL = curProtocol + curDomain + url;
                }
                history.pushState(null, null, historyURL);

                $("html, body").animate({ scrollTop: scrolltop }, 300, function(){
                    $('.lazy-loading').lazy(lazyDefaultSettings);
                });

                if(filterSet){
                    $('#reset-filter-btn').removeClass('disabled').prop("disabled",false);
                }
                else {
                    $('#reset-filter-btn').addClass('disabled').prop("disabled",true);
                }
            }
        });
    });
    */

    $('body').on( 'submit', '#event-search-form', function(e){
        e.preventDefault();

        hideEventFilterCanvas();

        var url = $(this).attr( 'action' );
        var formData = $(this).serialize().replace(new RegExp('%5B%5D', 'g'), '[]');
        var filterSet = false;

        if(!$(this).find('input[name="s"]').val()){
            formData = formData.replace('s=&', '');
            formData = formData.replace('s=', '');
        }
        else {
            filterSet = true;
        }

        if(!$(this).find('select[name="m"]').val()){
            formData = formData.replace('m=&', '');
            formData = formData.replace('m=', '');
        }
        else {
            filterSet = true;
        }

        if(formData.indexOf('c[]') != -1){
            filterSet = true;
        }

        if(formData.slice(-1) == '&'){
            formData = formData.substring(0, formData.length - 1);
        }

        if(formData) {
            var historyURL = curProtocol + curDomain + url + '?' + formData;
        }
        else {
            var historyURL = curProtocol + curDomain + url;
        }
        history.pushState(null, null, historyURL);

        /*
        var scrolltop = $('.line-content').first().offset().top - $('.navbar-fixed-top').height() - 30;
        $("html, body").animate({ scrollTop: scrolltop }, 300);
        */
    });

    var wto;
    $('body').on('keyup', '#event-filter-canvas input[name="s"]', function(){
        clearTimeout(wto);
        var clearIcon = $(this).closest('.input-with-clear-btn').find('i');
        var inputVal = $(this).val();
        wto = setTimeout(function() {
            if(inputVal.length > 0){
                clearIcon.removeClass('hidden').show();
            }
            else {
                clearIcon.hide();
            }
            updateEventFilters();
        }, 500);
    });

    $('body').on('change', '#event-filter-canvas select[name="m"], #event-filter-canvas input[name="c[]"]', function(){
        updateEventFilters();
    });

    $('body').on( 'click', '#reset-filter-btn', function(e){
        e.preventDefault();

        //$('#event-filter-canvas').removeClass('opened');
        $('#overlay-content').addClass('loading');
        $('#event-filter-canvas').addClass('loading');
        $('#reset-filter-btn').addClass('disabled').prop("disabled",true);

        var url = $(this).closest('form').attr( 'action' );
        $(this).closest('form').find('input[name="s"]').val('');
        $(this).closest('form').find('select[name="m"]').val('');
        $(this).closest('form').find('input[name="c[]"]').val('');

        $.ajax({
            url: url,
            type: 'GET',
            cache: false,
            contentType: false,
            processData: false,
            success: function(data){
                var jquery_data = $(data);

                if( jquery_data.find('#loadMoreBtn').length > 0 ){
                    if($('#loadMoreBtn').length > 0){
                        $('#loadMoreBtn').html(jquery_data.find('#loadMoreBtn').html());
                        $('#loadMoreBtn').removeClass('loading').show();
                    }
                    else {
                        $('.line-content').append('<div id="loadMoreBtn">' + jquery_data.find('#loadMoreBtn').html() + '</div>');
                        $('#loadMoreBtn').show();
                    }
                }
                else {
                    $('#loadMoreBtn').hide();
                }
                $('#content-section .ajax-content').html(jquery_data.find('.ajax-content').html());
                $('#event-filter-canvas').html(jquery_data.find('#event-filter-canvas').html());
                deactivateScrollLock();
                //deactivateOverlay();
                $('#overlay-content').removeClass('loading');
                $('#event-filter-canvas').removeClass('loading');
                var scrolltop = $('.line-content').first().offset().top - $('.navbar-fixed-top').height() - 30;

                var historyURL = curProtocol + curDomain + url;
                history.pushState(null, null, historyURL);

                $("html, body").animate({ scrollTop: scrolltop }, 300, function(){
                    activateScrollLock();
                    $('.lazy-loading').lazy(lazyDefaultSettings);
                });

                //$('#reset-filter-btn').hide();
            }
        });
    });

    $('body').on('click', '.input-with-clear-btn i', function(){
        $(this).hide();
        $(this).closest('.input-with-clear-btn').find('input').val('');

        if($(this).closest('#event-filter-canvas').length > 0){
            updateEventFilters();
        }
    });
});

$(window).scroll(function(){
    if($('#content-section.event-calendar .line-content').length > 0){
        if ($('#content-section.event-calendar .line-content').isOnScreen()) {
            showEventFilter();
        }
        else {
            hideEventFilter();
        }
    }
});

$(function() {
    var resizeEnd;
    var curWidth = $(window).width();
    var widthChange = false;
    $(window).on('resize', function() {
        if($(this).width() != curWidth){
            curWidth = $(this).width();
            widthChange = true;
            if($('#navbar-collapse:visible').length > 0 && $('.navbar-fixed-top .navbar-toggle:visible').length == 0) {
                $('nav.navbar-fixed-top').addClass('trans');
            }
        }
        clearTimeout(resizeEnd);

        resizeEnd = setTimeout(function() {
            if(widthChange){
                $(window).trigger('resize-width-end');
            }
            else {
                if($('#navbar-collapse:visible').length > 0 && $('.navbar-fixed-top .navbar-toggle:visible').length == 0) {
                    $('nav.navbar-fixed-top').removeClass('trans');
                }
            }
            widthChange = false;
            $(window).trigger('resize-end');
        }, 100);
    });
});

$(window).on('resize-end', function() {
    adjustImagesToVerticalGrid();
    adjustToGridWidth();
    buildGridDisplay();
    resizeEmbeddedContent();
    setHiddenRealSizeElems();
    $('.img-responsive:not(.img-fixed-size-container)').each(function(){
        adjustResponsiveImageToVerticalGrid($(this));
    });
    centerCaptionInHeaderBox();
    if ( $('.back-button-navbar:visible').length > 0 ){
        $('body').addClass( 'has-back-button' );
    }
    else {
        $('body').removeClass( 'has-back-button' );
    }
    reInitScrollPane();

    initOverflowBoxes();
    adjustImagesToVerticalGrid();
    checkEventFilterHeight();
});

$(window).on('resize-width-end', function() {
    resizeNavbarMenu();
    alignRightMobileNavFeatures();
    adjustCookieBannerPadding();
    if ($('#content-section.event-calendar .line-content').length > 0){
        if ($('#content-section.event-calendar .line-content').isOnScreen()) {
            showEventFilter();
        }
        else {
            hideEventFilter();
        }
    }
});